@font-face {
    font-family: "sequel";
    src: url("../static/sequel.ttf");
}

*
{
    margin: 0;
    padding: 0;
    touch-action: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html,
body
{
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.webgl
{
    width: 100%;
    height: 100%;
    display: block;
}

.base
{
  /* background-color: rgba(1, 0, 1, 0.1); */
    /* font family and sizing */
    font-family: sequel;
    font-size: 30px;
    position: relative;
    display: inline-block;
    

}

.topline{
    position: relative;
    /* left:10%; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.first_cap::first-letter {
    font-size: 40px;
}

.bottomline{
    transform: translateY(25%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
}

.centered
{
        display: flex;
            justify-content: space-evenly;
            align-items: center;
}

.slide_window
{
    height:40px;
    overflow: hidden;
}

.smaller {
    height: 40px;
    /* width: 380px; */
    top:-101px;
    font-size: 30px;
    /* color: rgb(157,209,248); */
    color: rgb(0, 0, 0);
    -webkit-text-stroke: 0px;
    -webkit-text-stroke-color: rgb(20, 56, 84);
    animation-name: move_divs;
    animation-duration: 14s;
    animation-iteration-count: infinite;
    /* animation-direction: reverse; */
    /* animation-timing-function: ease-in; */
}

@keyframes move_divs
{
    0% {top: -160px;}
    21.42% {top: -160px;}
    25% {top:-120px;}
    46.42% {top: -120px;}
    50% {top:-80px;}
    71.42% {top: -80px;}
    75% {top:-40px;}
    96.42% {top: -40px;}
    /* 78.57% {top:-0px;} */
    100% {top: -0px;}
}

.container
{
    position: absolute;
    /* width: 100%; */
    /* left:  */
    width: 400px;
    top:15%;
    left: 50%;
    transform: translateX(-50%);
}

.logo
{
    background-image: url("../static/dark_orb.jpg");
    background-size: cover;
            background-position: center;
                background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgba(1, 0, 1, 0.1);
    
}

.instagram{
        background-image: url("../static/IG.png");
        background-size: 70%;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 1);
}

.twitter {
    background-image: url("../static/Twitter.png");
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255,1);
}

.socials{
    position: absolute;
    left: 0%;
    bottom:0%;
    width: 150px;
    height: 80px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;
    background-color: rgba(1, 1, 1, 0.04);
}